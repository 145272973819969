@use '~@msaf/core-react/src/styles/0-settings/settings.scss' as s with (
  // Theme colors
  $color--primary: #00670a,
  $color--primary-active: #2b641d,
  $color--primary-accent-1: #548a4f,
  $color--primary-accent-2: #8bb188,
  $color--primary-accent-3: #c4d8c4,
  $map-marker-styles: ('property': #f36d21)
);

@use '~@msaf/core-react/src/styles/1-mixins/mixins.scss' as mixins;
@use '~@msaf/core-react/src/styles/2-resets/generic.scss';
@use '~@msaf/core-react/src/styles/3-base/base.scss';
@use '~@msaf/core-react/src/styles/4-layout/layout.scss';
@use '~@msaf/core-react/src/styles/5-components/components.scss';
@use '~@msaf/core-react/src/styles/6-trumps/trumps.scss';

// PDF sizes are based on puppeteer A4 default values
$pdf-page-height: 296mm;
$pdf-page-width: 210mm;
$pdf-page-margin: 19mm;

// TODO: Fix msaf core?
.c-image-with-actions__actions {
  z-index: 1000;
}

.c-marker-icon--gate,
.c-marker-icon--trough,
.leaflet-marker-icon.c-marker-icon--gate,
.leaflet-marker-icon.c-marker-icon--trough {
  background-color: #bb5a00;
}

.leaflet-tooltip.c-polygon-label {
  background-color: unset;
  border: unset;
  box-shadow: unset;
  text-shadow: unset;
  color: #3a3a3a;
}

// TODO: Should probably be moved to msaf-core
// Fixes padding so scroll bar appears in the correct place
.c-map-context-panel {
  @include mixins.ipad-portrait-up {
    padding: 0px;
  }
}

.c-map-context-panel__content,
.c-map-context-panel__header {
  padding: s.$size--200;
}

// TODO: Should probably be moved to msaf-core
// Fixes padding on cards within the side panel.
.c-map-context-panel .c-card__content {
  padding: s.$size--400;
}

.c-btn--form-field {
  margin-bottom: s.$size--400;
}

.leaflet-control {
  font-family: s.$family--primary;
  font-size: s.$font-size--300;
}

.c-modal__portal .c-modal-image {
  max-width: 90%;
  max-height: 100%;

  .c-loading-indicator {
    display: block;
    margin: 30% auto;
  }

  .c-loading-indicator__icon {
    height: 50px;
    width: 50px;
  }

  .c-modal__image {
    width: 100%;
    height: auto;
    margin-top: s.$size--300;
  }

  .c-modal-image__header {
    display: flex;

    h2 {
      margin: 0;
    }
  }

  .c-modal-image__header h2 {
    flex-grow: 1;
  }

  .c-modal-image__header > button,
  a {
    margin-left: s.$size--100;
  }
}

.c-map-context-panel {
  .c-divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .c-file-manager {
    margin: 0;
  }

  .c-file-card__header {
    display: block;
  }

  .c-file-summary {
    margin-bottom: 10px;
  }

  .c-file-upload {
    margin: 10px 0;
    padding: 10px;
  }

  .c-file-upload__instructions-text {
    text-align: center;
  }

  .c-file-upload__icon {
    display: none;
  }
}

.c-page-header__tags .c-tag {
  margin-right: 5px;
}

.c-map-menu-panel__content > .c-labelled-group {
  margin-bottom: s.$size--400;
}

.c-map-menu-panel__content .c-labelled-group__label {
  margin-bottom: s.$size--200;
}

.c-map-menu-panel__content .c-divider--small-vert-spacing {
  margin-bottom: s.$size--400;
}

.c-full-screen-image__container {
  background: rgb(249, 249, 250);
  inset: 0;
  position: absolute;
}

.c-full-screen-image__image {
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

/* Make read only and disable fields appear the same. This should
possibly be included in @msaf/core but I'm not sure if the
distinction there is deliberate. */
.c-form-field__input {
  .c-text-input--disabled,
  .c-text-input--disabled:focus,
  .c-text-input--disabled:hover,
  .c-read-only-field {
    color: #626e84;
  }
}

.c-table__cell--content {
  max-width: s.$size--1200;
  white-space: initial;
}

.c-form-text {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #626e84;
}

.l-pdf-document {
  background-color: #ffffff;
}

.l-pdf-container--margin-small {
  margin-top: 2rem;
}

.l-pdf-container--margin-medium {
  margin-top: 4rem;
}

.l-pdf-container--page-break-before-always {
  page-break-before: always;
}

.l-pdf-container--page-break-after-always {
  page-break-after: always;
}

.l-pdf-container--page-break-after-auto {
  page-break-after: auto;
}

.l-pdf-container--page-break-after-avoid {
  page-break-after: avoid;
}

.c-container__field--margin-top-small {
  margin-top: 1rem;
}

.c-container__field--margin-none {
  margin: 0;
}

.l-pdf-page__single {
  height: 100vh;
  width: 100vw;
  transform-origin: bottom left;
  page-break-before: always;
  page-break-after: always;
  margin: 0 auto;

  .c-leaflet-map.c-leaflet-map--inline {
    height: inherit;
    width: inherit;
    max-height: inherit;
    margin: 0;
    overflow: hidden;
    align-items: stretch;
  }

  .l-pdf-container--landscape {
    transform-origin: top left;
    transform: rotate(-90deg) translate(-100%, 0);
    display: flex;
    flex-direction: column;
    height: $pdf-page-width - ($pdf-page-margin * 2); // page margin is representative of one side
    width: $pdf-page-height - ($pdf-page-margin * 2); // page margin is representative of one side
  }
}

.l-pdf-page__not-found {
  height: 100vh;
  width: 100vw;
  transform-origin: bottom left;
  page-break-before: always;
  page-break-after: always;
  margin: 0 auto;
}

.l-pdf-page__not-found-text-slant {
  color: #bbb;
  transform: rotate(-90deg);
}

.c-pdf-page__banner--flex-end {
  display: flex;
  justify-content: flex-end;
}

// Puppeteer supports break-inside for block elements only. By default, elements are `display: block`.
.c-container__field--no-break-inside {
  break-inside: avoid !important;
}

// Puppeteer supports break-inside for block elements only. By default, elements are `display: block`.
.l-section--no-break-inside {
  break-inside: avoid !important;
}

.c-field--no-vertical-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.c-table {
  margin-top: 24px;
  margin-bottom: 0;
}

.c-field__heading--margin-small {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-field__heading--margin-top-small {
  margin-top: 2rem;
  margin-bottom: 0;
}

.c-pdf-repeating-field__container {
  display: grid;
  gap: 1rem;
}

.c-pdf-container__map-legend {
  padding-top: 5px;
  z-index: 1000;
  display: flex;
  gap: 20px;
  flex-flow: row wrap;
}

.c-pdf-container__map-legend-item {
  display: flex;
  gap: 5px;
  align-items: center;
}

.c-modal__body .c-typeahead {
  max-width: 640px;
}

// Apply custom styling to the PDF generator tables
.l-pdf-document {
  .c-table__header-label {
    display: flex;
    width: 100%;
    border: none;
    background: none;
    padding: 0;
  }

  .c-table__cell {
    padding: s.$size--200 s.$size--300 s.$size--200 s.$size--100;
    border-bottom: 1px solid s.$color--gray-light;
    font-size: s.$font-size--100;
    height: s.$size--500;
    vertical-align: middle;
    white-space: nowrap;
  }

  .c-table__cell--content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.c-pdf-read-only-field--disclaimer {
  .c-read-only-field {
    font-size: s.$font-size--100;
  }
}

.pdf-subheading {
  font-size: s.$font-size--200;
}

/* Over rides styles set by leaflet to ensure zoom control icons are centered */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  display: block;
  margin: auto;
}

@media print {
  body {
    /*
    background-color is required to prevent puppeteer from adding a gray background-color
    to the end of a pdf document
    */
    background-color: white;
  }

  .p--fineprint {
    font-size: s.$font-size--100;
    margin: 0;
  }

  .pdf-subheading--font-size {
    font-size: s.$font-size--200;
  }
}
